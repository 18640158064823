// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".U8RAuRrFVJdSWPsWYJ47{padding-top:38px}.RqbMBQ4aRVb3_OWvZoI2{cursor:pointer;margin-left:5px;margin-right:5px;font-size:18px;vertical-align:middle;color:gray}", "",{"version":3,"sources":["webpack://./src/code-studio/components/progress/bulk-lesson-visibility-toggle.module.scss"],"names":[],"mappings":"AAAA,sBAGE,gBAAA,CAGF,sBACE,cAAA,CACA,eAAA,CACA,gBAAA,CACA,cAAA,CACA,qBAAA,CACA,UAAA","sourcesContent":[".container {\n  // Align the top of the buttons with the top of the SectionAssigner dropdown\n  // to its left.\n  padding-top: 38px;\n}\n\n.infoTipIcon {\n  cursor: pointer;\n  margin-left: 5px;\n  margin-right: 5px;\n  font-size: 18px;\n  vertical-align: middle;\n  color: gray;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"container": "U8RAuRrFVJdSWPsWYJ47",
	"infoTipIcon": "RqbMBQ4aRVb3_OWvZoI2"
};
export default ___CSS_LOADER_EXPORT___;
